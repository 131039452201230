.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.TopRow {
  flex: 0 0 auto;
  text-align: center;
}
.BottomRow {
  flex: 1 1 auto;
}

.Panel {
  display: flex;
  flex-direction: column;
}

.PanelContent {
  height: 100%;
  width: 100%;
  background-color: var(--baimo-panel-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5rem;
}