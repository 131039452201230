/* .ant-layout .ant-layout-header {
  height: 30px !important;
  padding-inline: 25px !important;
  color: #FFFFFF;
  line-height: 30px !important;
  font-weight: 600;
  background: #001529;
} */


.ant-menu-dark {
  color: #FFFFFF;
}

.ant-menu-item-active {
  background: #FFFFFF;
  color: #001529;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected {
  background: #FFFFFF;
  color: #001529;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu {
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0px;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-inline: 20px;
  margin-inline: 0px;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-active, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-ant-menu-item-active {
  background: #FFFFFF;
  color: #001529 !important;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.ant-menu-horizontal {
  border: 0;
}

.ant-menu-item {
  /* font-weight: 800; */
  font-size: 16px;
}

.ant-menu-title-content {
  /* font-weight: 600; */
  font-size: 16px;
}

.ant-menu-inline .ant-menu-item {
  height: 30px;
  line-height: 30px;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-item, NavItems.ant-menu-vertical >.ant-menu-item, NavItems.ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, NavItems.ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
  height: 30px !important;
  line-height: 30px !important;
}

.ant-menu-horizontal {
  line-height: 30px;
  border: 0;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  box-shadow: none;
  text-align: center;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-inline: 10px;
  margin-inline: 0px;
  text-align: center;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after {
  border-width: 2px;
  border-bottom-color: transparent;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after {
  border-width: 2px;
  border-bottom-color: transparent;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-active:hover::after {
  border-width: 2px;
  border-bottom-color: transparent;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-open:hover::after {
  border-width: 2px;
  border-bottom-color: transparent;
}

.ant-menu-inline >.ant-menu-item, .ant-menu-vertical >.ant-menu-item, :where(.css-dev-only-do-not-override-1e67qka).ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
  height: 30px;
  
  line-height: 30px;
}


.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: rgba(0, 0, 0, 0.0);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 25px;
  /* padding-left: 28px !important; */
}

