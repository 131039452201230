.home-bg {
  width: 100%;
  background: url(https://img.alicdn.com/imgextra/i4/O1CN01Ri0dNS26K5UcRKrrU_!!6000000007642-2-tps-280-1400.png) repeat-x;
  background-position: 0 -100px;
  background-size: 140px 600px;
  height: 100%;
  z-index: 1;
  /* overflow: hidden; */
}

.home-logo {
  width: 460px;
    height: 213px;
    background: url(https://img.alicdn.com/imgextra/i4/O1CN01Gk5nSa1nkDO5AjXlN_!!6000000005127-2-tps-920-426.png) no-repeat;
    background-size: 460px 213px;
}

.home-button {
  background-image: linear-gradient(224deg,#0071da 0%,#1890ff 100%,#1890ff 100%);
  width: 180px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  margin: 47px 0 80px 0px;
  cursor: pointer;
}

.home-slogan1 {
  background: linear-gradient(#0071da, #1890ff, #1890ff);
  color: transparent;
  -webkit-background-clip: text;
  font-size: 70px;
  font-weight: 900;
  margin: 0 auto 6px;
}

.home-slogan2 {
  background: linear-gradient(#63829a, #476175, #324859);   
  color: transparent;
  -webkit-background-clip: text;
  font-size: 70px;
  font-weight: 900;
  margin: 0 auto 14px;
}

.home-slogan {
  font-size: 20px;
  color: #708293;
  text-align: center;
  /* width: 460px; */
}

.home-footer {
  background: #f1f5f7;
  padding: 20px 40px;
  color: #4a5e71;
  font-weight: 400;
}

.home-footer center{
  font-size: 14px;
  font-weight: 600;
}

.home-footer p{
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.home-features{
  color: #2a445d;
}

.home-features center{
  margin: 40px 0 0 0;
}