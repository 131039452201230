:root {
  --baimo-background-code: rgba(0, 0, 0, 0.35);
  --baimo-background-default: #081120;
  --baimo-button-background: #2a3343;
  --baimo-button-background-hover: #454950;
  --baimo-button-border: #18181a;
  --baimo-code: #c67bff;
  --baimo-white: #ffffff;
  --baimo-horizontal-rule: #454950;
  --baimo-link: #dcadff;
  --baimo-panel-background: #eee;
  --baimo-panel-background-alternate: #202124;
  --baimo-solid-resize-bar: #454950;
  --baimo-solid-resize-bar-handle: rgba(255, 255, 255, 0.2);
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  background-color: var(--color-background-default);
  color: var(--color-default);

  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}



p {
  margin: 0;
}
