body {
  background-color: #f7f9fe;
  overflow: hidden;
}
.aside-content{
  height: 100%;
  width: 100%;
  /* border-right: 0.5px solid #e3e8f7; */
  background-color: #fff;
  padding: 10px;
}

/* .aside-track {
  position: absolute
} */


.pandel-resizer {
  flex: 0 0 0.8px;
  position: relative;
  outline: none;


  background-color: transparent;
}

/* .ResizeHandleOuter:hover {
  background-color: #1a73e8;
} */
.pandel-resizer[data-resize-handle-active] {
  background-color: #1a73e8;
}

.panel-menu {
  /* background-color: #f6f8fa; */
  background-color: #dfe4ea;
  height: 30px;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  /* border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0; */
}

.panel-menu .panel-name {
  line-height: 30px;
  padding-left: 10px;
  font-weight: 600;
  margin-right: auto;
  white-space: nowrap;
  color:#2f3542;
}

.panel-menu-btn {
  height: 26px;
  width: 26px;
  font-size: 18px;
  padding: 4px;
  margin: 2px 2px;
  color: #2f3542;
  cursor: pointer;
}

.panel-menu-btn:hover {
  background-color: #ced6e0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.panel-body {
  background-color: #fff;
  height: 100%;
  /* border: 0.5px solid #c8cbd2; */
  /* box-shadow: 0 8px 16px -4px #2c2d300c; */
  /* border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px; */
}

.panel-content {
  height: 100%;
  width: 100%;
  padding: 10px;  
}

.aside-btn {
  height: 40px;
  display: block;
  padding: 8px 5px 5px 5px;
  margin: 2px 5px;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  color: #747d8c;
  cursor: pointer;
}

.aside-btn:hover {
  background-color: #e2e5e9;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: none;
  transition: all 0.2s;
  background-color: #f6f8fa;
  border-right: 0.5px solid #e1e4e8;
}

.ant-layout .ant-layout-header {
  height: 32px !important;
  color: #FFFFFF;
  line-height: 24px !important;
  padding: 0;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid rgb(225, 228, 232);
  border-top: 1px solid rgb(225, 228, 232);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #c8cbd2;
  gap: 1rem;
}
.top-row {
  flex: 0 0 auto;
  text-align: center;
}
.bottom-row {
  flex: 1 1 auto;
}

.panel {
  display: flex;
  flex-direction: column;
}



div[data-attribute="vertical"] {
  width: 10px;
  margin: 0 -5px;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  cursor: col-resize;
}

div[data-attribute="vertical"]:hover {
  border-left: 5px solid rgba(225, 228, 232, 0.5);
  border-right: 5px solid rgba(225, 228, 232, 0.5);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

div[data-attribute="horizontal"] {
  height: 10px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

div[data-attribute="horizontal"]:hover {
  border-top: 5px solid rgba(225, 228, 232, 0.5);
  border-bottom: 5px solid rgba(225, 228, 232, 0.5);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}


div[data-type="Resizer"] {
  background: #e1e4e8;
  opacity: 1;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}



/* div[data-attribute="vertical"].fXAXjb:hover {
  border-left: 5.5px solid rgba(0,0,0,0.5);
  border-right: 5.5px solid rgba(0,0,0,0.5);
} */

.ant-layout .ant-layout-footer {
  padding: 0;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid rgb(225, 228, 232);
  border-top: 1px solid rgb(225, 228, 232);
}

.footer-btn {
  padding: 2px;
  margin: 1px 3px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: #747d8c;
  cursor: pointer;
}

.footer-btn:hover {
  background-color: #e2e5e9;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

/* Aside ------------------------------------------------------------------------------ */
.sider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aside-track {
  height: 40px;
  padding: 0px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #a4b0be; */
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0.1px 1px 2px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.aside-track textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 5px 0 0 0;
  border: none;
  resize: none;
  height: 100%;
  width: 100%;
  text-align: center;
  border-bottom-right-radius: 10px;
  background-color: transparent;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.aside-track textarea:focus {
  outline: none;
}

input:focus,
textarea:focus {
  outline: none;
  border: none;
}

.aside-track p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 5px 0 5px 5px;
  border: none;
  resize: none;
  height: 100%;
  width: 180px;
  text-align: center;
  border-bottom-right-radius: 10px;
  background-color: transparent;
  user-select: none;
  line-height: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}

.no-selected {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Menu ------------------------------------------------------------------------------ */
.menu-bar {
  padding: 3px 3px 3px 5px;
  /* border-bottom: 1px solid rgb(225, 228, 232);
  border-top: 1px solid rgb(225, 228, 232); */
}

.menu-bar .ant-btn {
  font-size: 14px;
  height: 24px;
  font-weight: 600;
  padding: 0px 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.menu-bar .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #e2e5e9;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 3px 18px;
  margin: 0px 4px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5714285714285714;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-divider,
:where(.css-dev-only-do-not-override-1e67qka).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider,
:where(.css-dev-only-do-not-override-1e67qka).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider {
  height: 0.5px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #d4d4d4;
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px 0px;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}


:root {
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 6px;
  --contexify-menu-radius: 6px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1),
    2px 4px 4px rgba(0, 0, 0, 0.1),
    3px 6px 6px rgba(0, 0, 0, 0.1);
  --contexify-menu-negatePadding: var(--contexify-menu-padding);
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 6px;
  --contexify-activeItem-radius: 4px;
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #3498db;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

@keyframes contexify_feedback {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.contexify {
  position: fixed;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--contexify-menu-bgColor);
  box-sizing: border-box;
  box-shadow: var(--contexify-menu-shadow);
  border-radius: var(--contexify-menu-radius);
  padding: var(--contexify-menu-padding);
  min-width: var(--contexify-menu-minWidth);
  z-index: var(--contexify-zIndex);
}

.contexify_submenu-isOpen,
.contexify_submenu-isOpen>.contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

.contexify_submenu-isOpen>.contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_submenu-isOpen>.contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}

.contexify .contexify_submenu {
  position: absolute;
  pointer-events: none;
  transition: opacity 0.265s;
  /* Initial submenu position */
  top: calc(-1 * var(--contexify-menu-negatePadding));
  left: 100%;
}

.contexify .contexify_submenu-bottom {
  bottom: calc(-1 * var(--contexify-menu-negatePadding));
  top: unset;
}

.contexify .contexify_submenu-right {
  right: 100%;
  left: unset;
}

.contexify_rightSlot {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  color: var(--contexify-rightSlot-color);
}

.contexify_separator {
  height: 1px;
  cursor: default;
  margin: var(--contexify-separator-margin);
  background-color: var(--contexify-separator-color);
}

.contexify_willLeave-disabled {
  pointer-events: none;
}

.contexify_item {
  cursor: pointer;
  position: relative;
}

.contexify_item:focus {
  outline: 0;
}

.contexify_item:not(.contexify_item-disabled):hover>.contexify_itemContent .contexify_rightSlot,
.contexify_item:focus .contexify_rightSlot {
  color: var(--contexify-activeRightSlot-color);
}

.contexify_item:not(.contexify_item-disabled)[aria-haspopup]>.contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-arrow-color);
}

.contexify_item[aria-haspopup]:focus>.contexify_itemContent .contexify_rightSlot,
.contexify_item:not(.contexify_item-disabled)[aria-haspopup].contexify_submenu-isOpen>.contexify_itemContent .contexify_rightSlot,
.contexify_item:not(.contexify_item-disabled)[aria-haspopup]:hover>.contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_item:not(.contexify_item-disabled):hover>.contexify_itemContent,
.contexify_item:not(.contexify_item-disabled):focus>.contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

.contexify_item:not(.contexify_item-disabled):hover>.contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}

.contexify_item-disabled {
  cursor: default;
  /* font-size: 20px; */
  font-weight: 600 !important;
  opacity: 1 !important;
}

.contexify_itemContent {
  padding: var(--contexify-itemContent-padding);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: var(--contexify-item-color);
  position: relative;
}

.contexify_item-feedback {
  animation: contexify_feedback 0.12s both;
}

.contexify_theme-dark {
  --contexify-menu-bgColor: rgba(40, 40, 40, 0.98);
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff;
}

.contexify_theme-light {
  --contexify-separator-color: #eee;
  --contexify-item-color: #666;
  --contexify-activeItem-color: #3498db;
  --contexify-activeItem-bgColor: #e0eefd;
  --contexify-activeRightSlot-color: #3498db;
  --contexify-active-arrow-color: #3498db;
}

@keyframes contexify_scaleIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 1;
  }
}

@keyframes contexify_scaleOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.contexify_willEnter-scale {
  transform-origin: top left;
  animation: contexify_scaleIn 0.3s;
}

.contexify_willLeave-scale {
  transform-origin: top left;
  animation: contexify_scaleOut 0.3s;
}

@keyframes contexify_fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contexify_fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.contexify_willEnter-fade {
  animation: contexify_fadeIn 0.3s ease;
}

.contexify_willLeave-fade {
  animation: contexify_fadeOut 0.3s ease;
}

@keyframes contexify_flipInX {
  from {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
  }

  to {
    transform: perspective(800px);
  }
}

@keyframes contexify_flipOutX {
  from {
    transform: perspective(800px);
  }

  to {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }
}

.contexify_willEnter-flip {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  transform-origin: top center;
  animation: contexify_flipInX 0.3s;
}

.contexify_willLeave-flip {
  transform-origin: top center;
  animation: contexify_flipOutX 0.3s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes contexify_slideIn {
  from {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }

  to {
    opacity: 1;
  }
}

@keyframes contexify_slideOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }
}

.contexify_willEnter-slide {
  transform-origin: top center;
  animation: contexify_slideIn 0.3s;
}

.contexify_willLeave-slide {
  transform-origin: top center;
  animation: contexify_slideOut 0.3s;
}

/*# sourceMappingURL=ReactContexify.css.map */

/* rewrite antd ======================================*/

.ant-layout {
  background: white;
}

.ant-popover .ant-popover-inner {
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}